import store from '@/store'
import * as auth from './auth'
import md5 from 'js-md5'
import constants from './constants'
import { Message, MessageBox } from 'element-ui'


export default function request(method, version, path, params) {
  const timestamp = Math.round(new Date / 1000);
  let headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization': store.getters.token,
    'Product-Mode': "READ",
    'Terminal-Code': "IPCHAIN2" + auth.getTerminalCode(),
    'Platform-Subject':  auth.getMid(),
    'Terminal-Type': "WEB",
    'Merchant-Id': "5",
    'Timestamp': timestamp,
    'Accept-Language': 'zh',
    'Signature': md5(version + path + (params === undefined ? '' : JSON.stringify(params)) + timestamp + constants.request.SignatureKey),
  };
  // if (opt.headers) {
  //   Object.assign(headers, opt.headers);
  // }
  // console.log(version + path + (params === undefined ? '' : JSON.stringify(params)) + timestamp + constants.request.SignatureKey);
  let request;
  if ('get' == method || 'GET' == method || 'head' == method || 'HEAD' == method) {
    request = new Request(process.env.VUE_APP_BASEURL + version + path, {
      method: method,
      headers: headers,
      mode: "cors"
    });
  } else {
    request = new Request(process.env.VUE_APP_BASEURL + version + path, {
      method: method,
      body: JSON.stringify(params),
      headers: headers,
      mode: "cors"
    });
  }

  return new Promise((resolve, reject) => {
    fetch(request).then(res => {
      // console.log(res);
      if (res.status > 200) {
        if (res.status == 503) {
           res.json().then(data=>{
               //提示错误
            Message({
              type: 'error',
              message: data.message,
              duration: 3000
            })
          }
          ).catch(e=>{
            console.log(e);
          });
       
        }else if(res.status==401){
          // location.reload() // 为了重新实例化vue-router对象 避免bug
          store.commit('logout');

          // 修复重定向Login页面循环跳转的bug
          const redirect = decodeURIComponent(`${window.location.pathname}`)
          if(redirect === "/Login"){
              return;
          }

          // window.location.href=`/Login?redirect=${window.location.pathname}`
          // return ;
        }
        reject(res);
      } else {
        let data = null;
        try {
          data = res.json().then(data=>{
            resolve({data, res});
          }
          ).catch(e=>{
            console.log(e);
            resolve({data:null, res});
          });
        } catch (e) {
          console.log(e);
          resolve({data:null, res});
        }
       
      }
    }).catch(e => {
      reject(e);
    })
  });

}

