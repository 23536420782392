import request from '@/utils/request'



export function createObligee(param) {
    return request(
        'post',
        '/v1',
       `/evidence/obligee`,
	   param
    );
}


export function queryObligeeList(pageNumber,pageSize) {
    return request(
        'post',
        '/v1',
       `/evidence/obligee/p/${pageNumber}-${pageSize}`
    );
}

export function queryObligeeDetail(obligeeCode) {
    return request(
        'get',
        '/v1',
       `/evidence/obligee/${obligeeCode}`
    );
}

// v1/ins/idcard/ocr/both  上传身份证，直接获取身份证双面信息;
export function ocrBoth(url) {
    return request(
        'post',
        '/v1',
       `/ins/idcard/ocr`,
       url
    );
}

// v1/evidence/right     post    勾选权利保证书，生成保证书图片
// {
//   "signature": "吕好",
//   "subject": "小学语文信息化改革"
// }
export function queryEvidenceRight(param) {
    return request(
        'post',
        '/v1',
       `/evidence/right`,
       param
    );
}