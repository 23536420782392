import request from '@/utils/request'



export function queryPaymentPrice() {
    return request(
        'get',
        '/v1',
       `/evidence/payment/price`
    );
}


export function applyExistOrder(params) {
    return request(
        'post',
        '/v1',
       `/evidence/exist`,
       params
    );
}


export function paymentExistOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/exist/payment/${orderNo}`,
       {}
    );
}


export function queryExistOrders(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/exist/p/${pageNumber}-${pageSize}`,
       params
    );
}

export function queryExistOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/exist/${orderNo}`
    );
}


export function queryExistOrderItems() {
    return request(
        'get',
        '/v1',
    // 查询所有
    //    `/evidence/exist/items`
    // 查询已完成
       `/evidence/exist/items/21`
    );
}

export function queryPaymentOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/payment/order/${orderNo}`
    );
}

export function queryPaymentOrderByOutBizNp(outBizNo,bizType) {
    return request(
        'get',
        '/v1',
       `/evidence/payment/order/${outBizNo}/${bizType}`
    );
}
// 登记
export function applyRegisterOrder(params) {
    return request(
        'post',
        '/v1',
       `/evidence/register`,
       params
    );
}

// 登记付款，重新付款  // // DCI数登支付
export function paymentRegisterOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/register/payment/${orderNo}`,
       {}
    );
}

export function queryRegisterOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/register/${orderNo}`
    );
}

export function queryRegisterOrderList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/register/p/${pageNumber}-${pageSize}`,
       params
    );
}

// 监测
export function applyDetectOrder(params) {
    return request(
        'post',
        '/v1',
       `/evidence/detect`,
       params
    );
}

export function queryDetectOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/detect/${orderNo}`
    );
}

export function queryDetectOrderList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/detect/p/${pageNumber}-${pageSize}`,
       params
    );
}


// 获取检测结果数量  /v1/evidence/obtain/summary/'+detectId
export function queryMonitorNum(orderNo) {
    return request(
        'get',
        '/v1',
        `/evidence/detect/summary/${orderNo}`
    );
}

// 获取检测结果详情列表
// export function queryMonitor(pageNumber, pageSize, orderId) {
//     return request(
//         'get',
//         '/v1',
//         `/evidence/obtain/${orderId}/${pageNumber}-${pageSize}`
//     );
// }
export function queryMonitor(pageNumber, pageSize, orderId) {
    return request(
        'get',
        '/v1',
        `/evidence/detect/result/${orderId}/${pageNumber}-${pageSize}`
    );
}



// 一键取证
// v1/evidence/obtain/chain/2334-234-230      POST方法，最后一个参数是取证订单的orderNo
export function goObtain(resultId) {
    return request(
        'post',
        '/v1',
        `/evidence/obtain/chain/${resultId}`
    );
}

export function paymentDetectOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/detect/payment/${orderNo}`,
       {}
    );
}


export function queryVipConfig() {
    return request(
        'get',
        '/v1',
       `/evidence/vip/config`
    );
}


export function queryVipInfo() {
    return request(
        'get',
        '/v1',
       `/evidence/vip`
    );
}

export function applyVip(params) {
    return request(
        'post',
        '/v1',
       `/evidence/vip`,
       params
    );
}

export function queryEvidenceSummary() {
    return request(
        'get',
        '/v1',
       `/evidence/summary`
    );
}

// 获取买单，卖单，挂卖数量
export function queryShopUserSummary() {
    return request(
        'get',
        '/v1',
        `/shop/user/summary`
    );
}

// 新增发布挂卖获取productId
export function applyProduct(params) {
    return request(
         'post',
         '/v1',
        `/shop/product/create`,
        params
    );
}

// 提交发布挂卖信息
export function applyGood(params) {
    return request(
         'post',
         '/v1',
        `/shop/goods/create`,
        params
    );
}

// DetailView里面获取商品信息/shop/info/${goodsId} get
export function getGoodInfo(goodsId) {
    return request(
         'get',
         '/v1',
        `/shop/goods/info/${goodsId}`,
    );
}

// 下架挂卖信息
export function offShelfGood(goodsId,goodsStatus) {
    return request(
         'get',
         '/v1',
        `/shop/goods/enable/${goodsId}/${goodsStatus}`,
     );
}


// 首页存证记录数量信息
export function queryExistEvidenceSummary(params) {
     return request(
         'post',
         '/v1',
        `/evidence/summary/exist`,
        params
     );
}

// 首页存证记录 => 存证列表
export function queryAllExistEvidence(pageNumber,pageSize,params) {
     return request(
         'post',
         '/v1',
        `/evidence/exist/all/${pageNumber}-${pageSize}`,
        params
     );
}

// 论文查重
export function queryRepetition(params) {
     return request(
         'post',
         '/v1',
        `/paper/repetition`,
        params
     );
}
// 论文查重支付 paper/payment/order/${orderNo}
export function paymentRepetition(orderNo) {
    return request(
        'get',
        '/v1',
       `/paper/payment/order/${orderNo}`
    );
}





// 全国学术论文(著作)版权登记认证服务平台 接口

//登录查询API:/evidence/register/list/1-10
export function queryRegisterList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/register/list/${pageNumber}-${pageSize}`,
       params
    );
}


//  查重接口 https://xx/v1/check/check
export function applyCheckOrder(params) {
    return request(
        'post',
        '/v1',
       `/check/check`,
       params
    );
}

// 把已经提交的订单重新付款吧
// v1/check/check/p/1-10
// 请求参数{"title":"","author":""}


// 重新付款：post
// v1/check/payment/订单号
export function queryCheckPaymentOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/check/check/payment/${orderNo}`
    );
}

// 查重type‘检测版本’商品列表: post
// v1/check/goods/p/1-10
export function queryCheckGoodsList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/check/goods/p/${pageNumber}-${pageSize}`,
       params
    );
}

// 查重列表
// v1/check/check/p/1-10
export function queryCheckOrderList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/check/check/p/${pageNumber}-${pageSize}`,
       params
    );
}

// 查重结果 查询
// check/check/900-8358392-594500
export function queryCheckOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/check/check/${orderNo}`
    );
}


//  -------------DCI接口：----------------
// 提交订单：v1/evidence/dci
// 查询：v1/evidence/dci/p/1-10
//           {
//    dciCode:"",
//   workName:""
// }
export function applyDCIOrder(params) {
    return request(
        'post',
        '/v1',
       `/evidence/dci`,
       params
    );
}
// 重新付款：post
// v1/evidence/dci/payment/订单号
export function queryDCIPaymentOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/dci/payment/${orderNo}`
    );
}
export function queryDCIOrderList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/dci/p/${pageNumber}-${pageSize}`,
       params
    );
}

// DCI订单详情
// v1/evidence/dci/900-8358828-802537
export function queryDCIOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/dci/${orderNo}`
    );
}

// DCI数登详情
// v1/evidence/dci/registration/900-8358828-802537
export function queryDCIRegistrationOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/dci/registration/${orderNo}`
    );
}


// 科研诚信，咨询新闻
// v1/cms/content/p/{pageNum}-{pageSize}
// { "type":"1" }      //1、2分别对应2个分类，所有不传值
export function queryContentList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/cms/content/p/${pageNumber}-${pageSize}`,
       params
    );
}
// v1/cms/content/{id} // 查询新闻详情
export function queryContentDetail(id) {
    return request(
        'post',
        '/v1',
       `/cms/content/${id}`
    );
}


// ----------------------------------------------------------8.13新增评价相关接口-----------------------------------------------------------------
    // 1.新建评价 /v1/evidence/evaluation
    // {
    //     "workName":"一带一路背景下我国跨境电商的现状与发展OK",  // 作品名称
    //     "workCategory":"1",                                    // 作品类型
    //     "creationNature":"1",                                 // 创作性质
    //     "creationCompletionDate":"2024-03-20",            // 完成日期
    //     "publicationStatus":"1",                             //发表状态
    //     "publicationPlatform":"电脑校园",                    //发表平台
    //     "publicationDate":"2024-05-18",                     // 发表日期
    //     "author":["许知远"],                                   // 作者
    //     "copyrightOwnerCodes":["900-8362393-960571"],   // 著作权人
    //     "registerCode":"鄂作登字-2024-A-00000035",        // 作品登记号
    //     "currency":"cny",                                    
    //     "syncBlockChain":"ethc",
    //     "paymode":"alipay",
    //     "items":[{"sourceCode":"900-8362863-722608","sourceName":"一带一路背景下我国跨境电商的现状与发展OK","used":"280077","downloadUrl":"https://ipchain.oos-hbwh.ctyunapi.cn/2/536871125/2024/03/26/read/member/2/536871125/copyright/evidence/files/“一带一路”背景下我国跨境电商的现状与发展OK.doc","fileType":"doc","md5Code":"51HufF36KGnPGln1rveBow=="}]
    // }
    export function applyEvaluationOrder(params) {
        return request(
            'post',
            '/v1',
           `/evidence/evaluation`,
           params
        );
    }

    // 2.获取评价列表（未登录状态）
    // 请求路径：/v1/evidence/evaluation/list/900-8365707-460642/1-10
    // 请求主体：
    // {
    //     "workName": "", //作品名称
    //     "status": "21",
    //     "keywords":""  //查询关键字
    // }

    // 3.获取评价列表（已登录），查询用户名下所有评价列表
    // 请求路径：/v1/evidence/evaluation/p/1-10
    // 请求主体：
    // {
    //     "workName": "", //作品名称
    //     "keywords":""  //查询关键字
    // }
    export function queryEvaluationList(pageNumber,pageSize,params) {
        return request(
            'post',
            '/v1',
           `/evidence/evaluation/p/${pageNumber}-${pageSize}`,
           params
        );
    }

    // 4.获取评价详情
    // 请求路径：/v1/evidence/evaluation/900-8365774-569502
    // 请求主体:无
    export function queryEvaluationDetail(orderNo) {
        return request(
            'get',
            '/v1',
           `/evidence/evaluation/${orderNo}`
        );
    }