export default {
   request: {
      SignatureKey: '4b424e19fd7287dc2378271f985bd0b8'
   },
   paymode: {
      'wechat': '微信',
      'alipay': '支付宝',
      'vip': '会员',
   },
   worksType: [
      {
         value: "1",
         label: "文字",
      },
      {
         value: "2",
         label: "口述",
      },
      {
         value: "3",
         label: "音乐",
      },
      {
         value: "4",
         label: "戏剧",
      },
      {
         value: "5",
         label: "曲艺",
      },
      {
         value: "6",
         label: "舞蹈",
      },
      {
         value: "7",
         label: "杂技",
      },
      {
         value: "8",
         label: "美术",
      },
      {
         value: "9",
         label: "建筑",
      },
      {
         value: "10",
         label: "摄影",
      },
      {
         value: "11",
         label: "电影",
      },
      {
         value: "12",
         label: "类似摄制电影方法创作的作品",
      },
      {
         value: "13",
         label: "工程设计图、产品设计图",
      },
      {
         value: "14",
         label: "地图、示意图",
      },
      {
         value: "15",
         label: "模型",
      },
      {
         value: "16",
         label: "录音制品",
      },
      {
         value: "17",
         label: "录像制品",
      },
      {
         value: "18",
         label: "其他",
      },
   ],
   creationNature: [
      {
         value: "1",
         label: "原创",
      },
      {
         value: "2",
         label: "改编",
      },
      {
         value: "3",
         label: "翻译",
      },
      {
         value: "4",
         label: "汇编",
      },
      {
         value: "5",
         label: "注释",
      },
      {
         value: "6",
         label: "整理",
      },
      {
         value: "7",
         label: "其他",
      },
   ],
   publishStatus: [
      {
         value: "1",
         label: "未发表",
      },
      {
         value: "2",
         label: "已发表",
      },
   ],
   ownership: [
      {
         value: "1",
         label: "个人作品",
      },
      {
         value: "2",
         label: "合作作品",
      },
      {
         value: "3",
         label: "法人作品",
      },
      {
         value: "4",
         label: "职务作品",
      },
      {
         value: "5",
         label: "委托作品",
      },
   ],
   right: [
      {
         value: "1",
         label: "发表权",
      },
      {
         value: "2",
         label: "著名权",
      },
      {
         value: "3",
         label: "修改权",
      },
      {
         value: "4",
         label: "保护作品完整权",
      },
      {
         value: "5",
         label: "复制权",
      },
      {
         value: "6",
         label: "发行权",
      },
      {
         value: "7",
         label: "出租权",
      },
      {
         value: "8",
         label: "展览权",
      },
      {
         value: "9",
         label: "表演权",
      },
      {
         value: "10",
         label: "放映权",
      },
      {
         value: "11",
         label: "广播权",
      },
      {
         value: "12",
         label: "信息网络传播权",
      },
      {
         value: "13",
         label: "摄制权",
      },
      {
         value: "14",
         label: "改编权",
      },
      {
         value: "15",
         label: "翻译权",
      },
      {
         value: "16",
         label: "汇编权",
      },
      {
         value: "17",
         label: "其他",
      },
   ],
   ownershipOpt: [
      {
        value: "1",
        label: "个人作品",
      },
      {
        value: "2",
        label: "合作作品",
      },
      {
        value: "3",
        label: "法人作品",
      },
      {
        value: "4",
        label: "职务作品",
      },
      {
        value: "5",
        label: "委托作品",
      },
   ],
   obtainType: [
      { value: "1", label: "原始" },
      { value: "2", label: "继承" },
      { value: "3", label: "承受" },
      { value: "4", label: "其他" },
   ],
   signatureType: [
      { value: "1", label: "本名" },
      { value: "2", label: "别名" },
      { value: "3", label: "匿名" },
   ],
   paperType: [
      { value: "1", label: "博士论文" },
      { value: "2", label: "硕士论文" },
      { value: "3", label: "本科论文" },
      { value: "4", label: "专科论文" },
      { value: "5", label: "发表、投稿论文" },
      { value: "6", label: "评审职称论文" },
      { value: "7", label: "图书专著论文" },
      { value: "8", label: "科研成课论文" },
      { value: "9", label: "项目基金论文" },
      { value: "10", label: "结项报告论文" },
      { value: "11", label: "专利论文" },
      { value: "12", label: "其他" },
   ],

   
   platform:[
      { label: '百度', value: 'BAIDU' },
      { label: '腾讯', value: 'TENCENT' },
      { label: '网易', value: 'NETEASE' },
      { label: '头条', value: 'TOUTIAO' },
      { label: '长江日报', value: 'CJN' },
      { label: '人民网', value: 'PEOPLE' },
   ],
   vipType:{
      'S':'白银会员',
      'G':'黄金会员',
      'D':'钻石会员',
   }
};
