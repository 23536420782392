// 用户实名认证
import * as auth from '@/utils/auth'

const state = {
    authInfo: null
}
const getters = {
    authInfo(state) {
        return state.authInfo;
    }
}
const mutations = {
    setAuthInfo(state,data) {
        state.authInfo = data;
        auth.setAuthInfo(data);
    }
}
export default {
    state,
    getters,
    mutations
}
